import { postNoToken } from "../Api/PostNoToken";
import { downloadNoToken } from "../Api/downloadNoToken";
import { getNoToken } from "../Api/getNoToken";
import { getToken } from "../Api/getToken";
import { postToken } from "../Api/postToken";
import { putNoToken } from "../Api/putNoToken";
import { putToken } from "../Api/putToken";
import ObjectToFormData from "../../utils/ObjectToFormData";
import { deleteToken } from "../Api/deleteToken";
import { postJsonToken } from "../Api/postJsonToken";

// Kullanıcıları  getirme
export const getUsers = (
  page,
  search = "",
  status = -1,
  id,
  taxYear = "",
  userId = "",
  limit = 10,
  paymentOption = null
) => {
  return getToken(
    `/Users?page=${page}${
      id != null ? `&assignId=${id.id}` : ""
    }&limit=${limit}${search.length > 0 ? `&searchKey=${search}` : ""}${
      status > -1 ? `&status=${status}` : ""
    }&taxYear=${taxYear}&userId=${userId}&paymentServiceId=${paymentOption}`
  );
};
// Kullanıcıların payment işlemlerini  getirme
export const getPaymentsOfUser = (userId = "") => {
  return getToken(`/Users/GetUserPayments?userId=${userId}`);
};
// Kullanıcıları  getirme
export const getRegisteredUsers = (page, isPayment = null) => {
  return getToken(
    `/Users/UserList?page=${page}&limit=10&isPayment=${isPayment}`
  );
};
// Silinmiş Kullanıcıları  getirme
export const getTrashedUsers = (page, search) => {
  return getToken(
    `/Users/TrashUsers?page=${page}&limit=10&searchKey=${search}`
  );
};
// Kullanıcının bilgilerini getirme
export const getUserInfo = (userId, taxYear = "") => {
  return getToken(`/Users/${userId}?taxYear=${taxYear}`);
};

//Kullanıcının kayıt olurken bilgilerini güncelleme
export const putRegisterUserInfo = (userId, form, userPartner) => {
  // Aynı adresi kullan dediyse formdan gelen adres kullanılır. Farklı adres girdiyse userpartnerin adresi kullanılır.
  const partnerAddres = userPartner.addressShow
    ? form.address
    : userPartner.address;

  let data = new FormData();
  data.append("Email", form.email);
  data.append("PhoneNo", `+49${form.phoneNo}`);
  data.append("Name", form.name);
  data.append("Surname", form.surname);
  data.append("Gender", form.gender || 3);
  data.append("BirthDate", new Date(form.birthDate).toISOString());
  data.append("Address.Street", form.address.street);
  data.append("Address.ApartmentNo", form.address.apartmentNo);
  data.append("Address.PostalCode", form.address.postalCode);
  data.append("Address.City", form.address.city);
  data.append("IBAN", form.iban || "");
  data.append("TaxIdentifyNo", form.taxIdentifyNo || "");
  // data.append('TaxYear', form.taxYear);
  data.append("MarriageStatus", form.marriageStatus);
  data.append("UserSign", form.signatureFile);
  data.append("isPartnerPayment", form.isPartnerPayment);

  if (form.childrenStatus) {
    form.userChilds.map((item) => {
      data.append(
        "UserChilds",
        JSON.stringify({
          ...item,
          taxYear: form.taxYear,
        })
      );
    });
  }

  if (form.workPlaceAddress.companyName != "") {
    data.append("UserCompanies.TaxYear", form.taxYear);
    data.append(
      "UserCompanies.CompanyName",
      form.workPlaceAddress.companyName || ""
    );
    data.append("UserCompanies.Street", form.workPlaceAddress.street || "");
    data.append("UserCompanies.No", form.workPlaceAddress.no || "");
    data.append(
      "UserCompanies.PostalCode",
      form.workPlaceAddress.postalCode || ""
    );
    data.append("UserCompanies.City", form.workPlaceAddress.city || "");
    data.append("UserCompanies.HomeOfficeDayCount", form.workPlaceAddress.homeOfficeDayCount || 0);
  }

  if (form.marriageStatus == 2) {
    data.append("UsersPartners.Name", userPartner.name);
    data.append("UsersPartners.Surname", userPartner.surname);
    data.append(
      "UsersPartners.MarriageDate",
      new Date(userPartner.marriageDate).toISOString()
    );
    data.append(
      "UsersPartners.BirthDate",
      new Date(userPartner.birthDate).toISOString()
    );
    data.append("UsersPartners.Address.Street", partnerAddres.street);
    data.append("UsersPartners.Address.ApartmentNo", partnerAddres.apartmentNo);
    data.append("UsersPartners.Address.PostalCode", partnerAddres.postalCode);
    data.append("UsersPartners.Address.City", partnerAddres.city);
    data.append("UsersPartners.IBAN", userPartner.iban || "");
    data.append("UsersPartners.TaxIdentifyNo", userPartner.taxIdentifyNo || "");
    data.append("UserPartnerSign", userPartner.signatureFile);
  } else {
    data.append("UsersPartners", null);
  }

  return putNoToken(`/Users/${userId}`, data);
};

//Kullanıcının gönderilen bilgilerini güncelleme
export const putUserInfoComeFromForm = (userId, form) => {
  const data = ObjectToFormData(form);
  return putToken(`/Users/${userId}`, data);
};

//Kullanıcı statü güncelleme
export const putUserStatus = (userId, taxYear, status, lang = "de") => {
  let data = {
    taxYear: taxYear,
    status: status,
  };
  return putToken(`/Users/UserStatus/${userId}?culture=${lang}`, data);
};
//Kullanıcı register kaydını tamamlama:
export const completeRegister = (userId, taxYear, isPartnerPayment) => {
  let data = {
    taxYear: Number(taxYear),
    userId,
    isPartnerPayment,
  };
  return postJsonToken(`/Users/CompleteRegister`, data);
};

//Kullanıcı kayıt olma
export const registrationUser = (form, userPartner, lang) => {
  // Aynı adresi kullan dediyse formdan gelen adres kullanılır. Farklı adres girdiyse userpartnerin adresi kullanılır.
  const partnerAddres = userPartner.addressShow
    ? form.address
    : userPartner.address;

  let data = new FormData();
  data.append("Email", form.email);
  data.append("Password", form.password);
  data.append("PhoneNo", `+49${form.phoneNo}`);
  data.append("Name", form.name);
  data.append("Surname", form.surname);
  data.append("Gender", form.gender || 3);
  data.append("BirthDate", new Date(form.birthDate).toISOString());
  data.append("Address.Street", form.address.street);
  data.append("Address.ApartmentNo", form.address.apartmentNo);
  data.append("Address.PostalCode", form.address.postalCode);
  data.append("Address.City", form.address.city);
  data.append("IBAN", form.iban);
  data.append("TaxIdentifyNo", form.taxIdentifyNo);
  data.append("TaxYear", form.taxYear);
  data.append("MarriageStatus", form.marriageStatus);
  data.append("UserSign", form.signatureFile);
  data.append("Culture", lang);
  data.append("isPartnerPayment", form.isPartnerPayment);

  if (form.workPlaceAddress.companyName != "") {
    data.append("UserCompanies.TaxYear", form.taxYear);
    data.append(
      "UserCompanies.CompanyName",
      form.workPlaceAddress.companyName || ""
    );
    data.append("UserCompanies.Street", form.workPlaceAddress.street || "");
    data.append("UserCompanies.No", form.workPlaceAddress.no || "");
    data.append(
      "UserCompanies.PostalCode",
      form.workPlaceAddress.postalCode || ""
    );
    data.append("UserCompanies.City", form.workPlaceAddress.city || "");
    data.append("UserCompanies.HomeOfficeDayCount", form.workPlaceAddress.homeOfficeDayCount || 0);

    
  }

  if (form.childrenStatus) {
    form.userChilds.map((item) => {
      data.append(
        "UserChilds",
        JSON.stringify({
          ...item,
          taxYear: form.taxYear,
        })
      );
    });
  }

  if (form.marriageStatus == 2) {
    data.append("UsersPartners.Name", userPartner.name);
    data.append("UsersPartners.Surname", userPartner.surname);
    data.append(
      "UsersPartners.MarriageDate",
      new Date(userPartner.marriageDate).toISOString()
    );
    data.append(
      "UsersPartners.BirthDate",
      new Date(userPartner.birthDate).toISOString()
    );
    data.append("UsersPartners.Address.Street", partnerAddres.street);
    data.append("UsersPartners.Address.ApartmentNo", partnerAddres.apartmentNo);
    data.append("UsersPartners.Address.PostalCode", partnerAddres.postalCode);
    data.append("UsersPartners.Address.City", partnerAddres.city);
    data.append("UsersPartners.IBAN", userPartner.iban || "");
    data.append("UsersPartners.TaxIdentifyNo", userPartner.taxIdentifyNo || "");
    data.append("UserPartnerSign", userPartner.signatureFile);
  } else {
    data.append("UsersPartners", null);
  }

  return postToken("/Users/Register", data);
};

//Kullanıcı kayıt olurken dosya yükleme kısmı
export const sendDocument = (info, document, userId, taxYear) => {
  let data = new FormData();

  data.append("UserId", userId);
  data.append("CategoryTypeId", document);
  info.map((item) => {
    data.append("FileData", item);
  });
  data.append("TaxYear", taxYear);

  return postNoToken(`/File/AddFile`, data);
};

//Kullanıcı kayıt olurken dosya yükleme kısmına eklenecek not
export const sendDocumentNote = (text, typeId, userId, taxYear) => {
  let data = {
    userId: userId,
    taxYear: taxYear,
    plainText: text,
    directoryType: typeId,
  };

  return postNoToken(`/DocumentNote`, data);
};

//Kullanıcı kayıt olurken dosya yükleme kısmına eklenecek notun güncellenmesi
export const updateDocumentNote = (text, typeId, userId, taxYear) => {
  let data = {
    userId: userId,
    taxYear: taxYear,
    plainText: text,
    directoryType: typeId,
  };

  return putNoToken(`/DocumentNote`, data);
};

//Kullanıcı kayıt olurken yüklediği dosyayı silme işlemi
export const deleteDocumentFile = (activeDocument, file, userId, taxYear) => {
  let data = {
    fileName: file.name,
    categoryTypeId: activeDocument,
    userId: userId,
    taxYear: taxYear,
  };
  return postNoToken(`/File/DeleteFile`, data);
};

//Kullanıcı emaili daha önce kullanılmış mı kontrolü
export const controlEmail = (email) => {
  let data = {
    email: email,
  };
  return postNoToken(`/Register/CheckEmail`, data);
};

//Kullanıcı telefon numarasını daha önce kullanılmış mı kontrolü
export const controlPhone = (phone) => {
  let data = {
    phoneNo: `+49${phone}`,
  };
  return postNoToken(`/Register/CheckNumber`, data);
};

// Kullanıcının telefonuna sms gönderme
export const sendPhoneCode = (phoneNo, lang) => {
  let data = {
    phoneNo: `+49${phoneNo}`,
    culture: lang,
  };
  return postNoToken(`/SMS/SendValidateMessage`, data);
};

//Kullanıcıya gelen sms kodunu gönderme
export const sendPhoneSmsCode = (phoneNo, code) => {
  let data = {
    phoneNo: `+49${phoneNo}`,
    smsCode: code,
  };
  return postNoToken(`/SMS/ValidateSmsCode`, data);
};

//Kullanıcıya şifresini unuttuğu için mail atma isteği
export const sendEmailPasswordLink = (email, lang) => {
  let data = {
    email: email,
    culture: lang,
  };
  return postNoToken(`/Users/ForgotPassword`, data);
};

//Kullanıcıya şifremi unuttum da şifre yenileme isteği
export const sendNewPassword = (password, token) => {
  let data = {
    newPassword: password,
    token: token,
  };
  return postNoToken(`/Users/RenewPassword`, data);
};

//Kullanıcı ödeme isteği
export const paymentTax = (
  userId,
  paymentOption,
  lang,
  year,
  operation,
  campanyId,
  isPartnerPayment
) => {
  let data = {
    userId: userId,
    paymentServiceId: Number(paymentOption),
    culture: lang,
    taxYear: year,
    operationType: operation,
    campanyId: campanyId,
    isPartnerPayment: isPartnerPayment,
  };
  return postNoToken("/Payment/RegisterPayment", data);
};

//Kullanıcı ödeme isteği yanıt kontrolü
export const paymentControl = (userId) => {
  return getNoToken(`/Payment/Checkout?userId=${userId}`);
};

//kullanıcı vergi beyannemesi aldığı yılların listesi
export const getYears = (userId) => {
  return getToken(`/TaxYear?userId=${userId}`);
};

//Kullanıcı örnek vergi beyannamesi gösterimi
export const getTaxReturn = (info, sign) => {
  let data = new FormData();
  const fullName = info.name + " " + info.surname;

  data.append("Fullname", fullName);
  data.append("TaxNo", info.taxIdentifyNo);
  data.append("BirthDate", new Date(info.birthDate).toISOString());
  data.append("City", info.address.city);
  data.append("Date", new Date().toDateString());
  data.append("Sign", info.signatureFile == null ? sign : info.signatureFile);

  return downloadNoToken("/File/GetTaxDeclaration", data);
};

export const adminDeleteUser = (id) => {
  return deleteToken(`/Users/${id}`);
};
export const adminRescueUser = (id) => {
  return putToken(`/Users/RescueUser/${id}`);
};

// Yıla not ekleme
export const addTaxYearNote = (plainText, userId, taxYear) => {
  let data = {
    plainText: plainText,
    userId: userId,
    taxYear: taxYear,
  };
  return postJsonToken(`/TaxYearNote`, data);
};

// Yıl ve kullanıcıya göre not listeleme
export const getTaxYearNoteList = (userId, taxYear, page = 1, limit = 5) => {
  return getToken(
    `/TaxYearNote?TaxYear=${taxYear}&UserId=${userId}&page=${page}&limit=${limit}`
  );
};

// Yıla atılan notu güncelleme
export const updateTaxYearNote = (noteId, plainText) => {
  let data = {
    plainText: plainText,
  };
  return putToken(`/TaxYearNote/${noteId}`, data);
};

// Yıla atılan notu silme
export const deleteTaxYearNote = (noteId) => {
  return deleteToken(`/TaxYearNote/${noteId}`);
};
