import { baseUrl } from '../Api';

/** Put without token */
export const putNoToken = (url, data) => {
    const axios = require('axios');

    let config = {
        method: 'put',
        url: `${baseUrl}${url}`,
        headers: {
            "Content-type": "application/json",
        },
        data: data
    }
    return axios(config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            // localStorageManager.userAuth.removeActiveUser();
            // window.location.href = '/user/giris-yap'
            return error;
        });
}