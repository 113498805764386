<template>
  <div class="selection">
    <div class="selection-menu">
      <img src="../../../assets/img/small-logo.png" alt="" />
      <div class="cards">
        <!-- <div class="card">
          <font-awesome-icon :icon="faFile" />
          <p>{{ $t("documents") }}</p>
        </div>
        <div class="card">
          <font-awesome-icon :icon="faCreditCard" />
          <p>{{ $t("bank") }}</p>
        </div> -->
        <div
          class="card text-center"
        >
          <router-link to="/registrieren" class="text-dark-blue">
            <font-awesome-icon :icon="faClipboard" />
            <p>{{ $t("tax") }}</p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faFile,
  faCreditCard,
  faClipboard,
} from "@fortawesome/free-regular-svg-icons";

export default {
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      faFile,
      faCreditCard,
      faClipboard,
    };
  },
};
</script>

<style lang="scss">
.selection {
  background-color: #f1f8fa;
  padding: 20px;
  .selection-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      height: 60px;
    }

    .cards {
      display: flex;

      .card {
        border: none;
        background-color: transparent;

        p {
          font-size: 14px;
        }

        svg {
          height: 26px;
        }

        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>