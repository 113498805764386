import { baseUrl } from '../Api';

/** token */
export const downloadNoToken = (url, data) => {

    const axios = require('axios');

    let config = {
        method: 'post',
        url: `${baseUrl}${url}`,
        responseType: "blob",
        headers: {
            "Content-type": "application/json",
        },
        data: data
    }
    return axios(config)
        .then((response) => {
            var file = new Blob([response.data], { type: 'application/pdf' });

            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function () {
                window.ReactNativeWebView?.postMessage(
                    JSON.stringify({
                        signatureUrl: reader.result,
                    })
                );
            };
            return URL.createObjectURL(file)
        })
        .catch((error) => {
            // localStorageManager.userAuth.removeActiveUser();
            // window.location.href = '/user/giris-yap'
            return error;
        });
}