<template>
  <div class="tax-returns-page">
    <SelectionMenu />
    <Menu />
    <div class="tax-returns">
      <h2 class="col-12 col-lg-8">{{ $t("myTaxReturn") }}</h2>
      <BaseTable
        i18n
        :loading="isLoading"
        :items="items"
        :count="count"
        :limit="10"
        :page="page"
        @change:page="SET_PAGINATION"
        :noSearch="true"
        :pages="pages"
        :headers="headers"
        noCaption
      >
        <template #taxYear="{ item }">
          <p>{{ item.taxYear }}</p>
        </template>
        <template #status="{ item }">
          <small
            :class="`badge badge-soft-${
              taxReturnStatus(item.status).class
            }`"
            >{{ $t(taxReturnStatus(item.status).text) }}</small
          >
         <router-link :to="`/pay/${activeUser.id}/${item.taxYear}`">
           <button class="blue-button bg-success mx-2 btn-xs" v-if="item.status === 6"> {{$t('payIt')}} </button>
         </router-link>
        </template>
      </BaseTable>
    </div>
  </div>
</template>

<script>
import BaseTable from "../components/base/Table/index.vue";
import SelectionMenu from "../components/start/base/SelectionMenu.vue";
import Menu from "../components/start/base/Menu.vue";
import { getUsers } from "../services/modules/User";
import { mapGetters } from "vuex";

export default {
  components: {
    BaseTable,
    SelectionMenu,
    Menu,
  },
  data() {
    return {
      headers: [
        { title: "taxYear", value: "taxYear", col: 12, md: 4, lg: 6 },
        { title: "status", value: "status", col: 3, md: 4, lg: 6 },
      ],
      count: 0,
      page: 1,
      isLoading: false,
      items: [],
    };
  },
  methods: {
    async userTaxReturnList(page = 1) {
      this.isLoading = true;
      const response = await getUsers(
        page,
        "",
        -1,
        { id: "" },
        "",
        this.activeUser.id
      );

      if (response.message === "OK") {
        this.items = response.data.items;
        this.count = response.data.count;
      }
      this.isLoading = false;
    },
    setDate(date) {
      return new Date(date + "+00:00").toLocaleString(this.$i18n.locale, {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    taxReturnStatus(val) {
      return val === 0
        ? { class: "info", text: "waiting" }
        : val === 1
        ? { class: "warning", text: "reviewing" }
        : val === 2
        ? { class: "success", text: "completed" }
        : val === 4
        ? { class: "secondary", text: "pendingConfirmation" }
        : val === 5
        ? { class: "primary", text: "transmittedFa" }
        : val === 6
        ? { class: "pink", text: "paymentWaiting" }
        : { class: "danger", text: "canceled" };
    },
    SET_PAGINATION(page) {
      this.page = page;
      this.userTaxReturnList(this.page);
    },
  },
  computed: {
    ...mapGetters(["activeUser"]),
    pages() {
      return Math.ceil(this.count / 10);
    },
  },
  mounted() {
    this.userTaxReturnList();
  },
};
</script>

<style lang="scss">
.tax-returns-page {
  .tax-returns {
    margin-top: 6rem;
    padding: 0px 30px;
  }
}
</style>