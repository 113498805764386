var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"o-table__body"},_vm._l((_vm.items),function(item,i){return _c('div',{key:'b' + i,staticClass:"o-table__body__item",class:{
      'o-table__body__item--active':
        _vm.checkedList.find((data) =>
          _vm.checkedKey.every((ck) => item[ck] === data[ck])
        ) !== undefined,
      'u-flex-wrap-wrap o-table__body__item--small-table': _vm.smallTable,
    }},[(_vm.checkable || _vm.singleCheckable)?_c('div',{staticClass:"col-xs-1"},[_c('Checkbox',{attrs:{"value":_vm.checkedList.find((data) =>
            _vm.checkedKey.every((ck) => item[ck] === data[ck])
          ) !== undefined},on:{"change":function($event){return _vm.$emit('change:checked', item)}}})],1):_vm._e(),_vm._l((_vm.headers),function(header,j){return _c('div',{key:i + '-' + j,class:`
            ${header.col ? `col-xs-${header.col}` : ''}
            ${header.sm ? `col-sm-${header.sm}` : ''}
            ${header.md ? `col-md-${header.md}` : ''}
            ${header.lg ? `col-lg-${header.lg}` : ''}
            ${header.xl ? `col-xl-${header.xl}` : ''}
            ${header.xxl ? `col-xxl-${header.xxl}` : ''}`},[(_vm.$scopedSlots[header.value])?_vm._t(header.value,null,{"item":item,"index":i}):_c('div',[_vm._v(" "+_vm._s(_vm.i18n ? _vm.$t(item[header.value]) : item[header.value])+" ")])],2)})],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }