<template>
  <div class="o-card my-4">
    <div class="o-card__title mb-0">
      <h4 v-if="title !== ''">
        {{ title }} <Chip>{{ countItem }}</Chip>
      </h4>
      <p v-if="description !== ''">
        <small>{{ description }}</small>
      </p>
    </div>
    <div v-if="newButtonSlot" class="u-display-none-sm-down">
      <slot name="new-button"></slot>
    </div>
    <div v-if="filterSlot" class="u-display-none-sm-down">
      <slot name="filter"></slot>
    </div>
    <div class="u-text-align-right">
      <Button
        v-if="filterSlot"
        class="u-display-none-sm-up"
        @click="showFilterDrawer = true"
        variant="primary"
      >
        <i class="icofont-filter"></i> {{ $t("filter") }}</Button
      >
    </div>
    <div
      :class="{ 'u-flex-wrap-wrap': smallTable }"
      class="row u-justify-content-space-between u-align-items-center u-flex-wrap-wrap-sm-down"
    >
      <div class="col-xs-12 col-md-6 col-lg-8">
        <p v-if="checkedList.length !== 0">
          {{
            $t("choosedUser", {
              count: selectedAll ? countItem : checkedList.length,
            })
          }}
          <small v-if="loadingAllData">
            {{ $t("gettingAllData") }}
          </small>
          <small
            @click="SET_CHECKED_ALL(true, true)"
            v-if="
              !selectedAll &&
              countItem !== checkedList.length &&
              checkedList.length !== 0 &&
              countItem !== items.length
            "
            class="o-table__choose-all"
          >
            {{
              loadingAllData
                ? $t("gettingAllData")
                : $t("chooseAll", { count: countItem })
            }}
          </small>
        </p>
      </div>
      <div v-if="!noSearch" class="col-xs-12 col-md-6 col-lg-4 form-style">
        <form @submit="SET_SUBMIT">
          <b-form-input
            type="text"
            block
            name="search"
            v-model="search"
            icon="search"
            @keyup="SET_SUBMIT"
            :placeholder="searchPlaceholder"
          ></b-form-input>
        </form>
      </div>
    </div>
    <div class="mt-3" :key="page">
      <b-pagination
        @change="SET_PAGINATION"
        v-model="page"
        :total-rows="count"
        :per-page="limit"
        ul-class="c-pagination"
        li-class="c-pagination__list"
        active-class="c-pagination__list--active"
        aClass="c-pagination__list__action"
      ></b-pagination>
    </div>
    <div class="o-table__footer-caption" v-if="!noCaption">
      <i class="o-table__footer-caption__text">{{
        $t("foundItem", { count: countItem })
      }}</i>
    </div>
    <div class="o-table">
      <Header
        :i18n="i18n"
        v-if="!smallTable"
        @change:scroll="(e) => (scrollLeft = e)"
        @change:checkedAll="SET_CHECKED_ALL"
        @change:sort="SET_CHANGE_SORT"
        :scrollLeft="scrollLeft"
        :checkedList="checkedList"
        :items="items"
        :checkable="checkable"
        :singleCheckable="singleCheckable"
        :allChecked="allChecked"
        :headers="headers"
      ></Header>
      <div v-if="loading" class="text-center mt-2">
        <b-spinner small style="width: 2rem; height: 2rem"></b-spinner>
      </div>
      <Body
        :i18n="i18n"
        @change:checked="SET_CHECK_LIST"
        :checkedList="checkedList"
        :headers="headers"
        :smallTable="smallTable"
        :checkable="checkable"
        :singleCheckable="singleCheckable"
        :checkedKey="checkedKey"
        :scrollLeft="scrollLeft"
        :items="items"
        v-else-if="headers.length !== 0 && items.length !== 0"
      >
        <template
          v-slot:[header]="{ item, index }"
          v-for="(header, i) in scopedSlots"
        >
          <div :key="'a' + i">
            <slot :item="item" :index="index" :name="header"></slot>
          </div>
        </template>
      </Body>
      <div class="text-center my-4" v-else>
        <img width="80" :src="noFoundImage" :alt="emptyText" />
        <p>
          <small>{{ emptyText }}</small>
        </p>
      </div>
      <div v-if="!noCaption" class="o-table__footer-caption">
        <i class="o-table__footer-caption__text">{{ captionText }}</i>
      </div>
      <div class="text-right mt-4" :key="page">
        <b-pagination
          @change="SET_PAGINATION"
          v-model="page"
          :total-rows="count"
          :per-page="limit"
          ul-class="c-pagination"
          li-class="c-pagination__list"
          active-class="c-pagination__list--active"
          aClass="c-pagination__list__action"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./Header.vue";
import Body from "./Body.vue";
const noFoundImage = require("../../../assets/images/no-found.png");
export default {
  data() {
    return {
      noFoundImage,
      init: false,
      search: "",
      selectedAll: false,
      showFilterDrawer: false,
      filterSlot: false,
      newButtonSlot: false,
      searchTimeout: -1,
      scrollLeft: 0,
      countItem: 0,
      scopedSlots: [],
      checkedList: [],
      headersData: [],
    };
  },
  props: {
    title: {
      default: "",
    },
    description: {
      default: "",
    },
    searchPlaceholder: {
      default: "",
    },
    captionText: {
      default: "",
    },
    headers: {
      default: () => [],
    },
    items: {
      default: () => [],
    },
    noSearch: {
      default: false,
      type: Boolean,
    },
    loadingAllData: {
      default: false,
      type: Boolean,
    },
    noCaption: {
      default: false,
      type: Boolean,
    },
    smallTable: {
      default: false,
      type: Boolean,
    },
    checkable: {
      default: false,
      type: Boolean,
    },
    singleCheckable: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    i18n: {
      default: false,
      type: Boolean,
    },
    emptyText: {
      type: String,
      default: function () {
        return this.$t("noFoundData");
      },
    },
    searchValue: {
      default: "",
    },
    checkedKey: {
      default: () => ["id"],
    },
    count: {
      default: 0,
    },
    page: {
      default: 1,
    },
    limit: {
      default: 12,
    },
    pages: {
      default: 0,
    },
    checkValue: {
      default: () => [],
    },
  },
  watch: {
    items() {
      if (this.count === 0) this.countItem = this.items.length;
      else this.countItem = this.count;
    },
    checkedList(data) {
      this.$emit("change:checkedList", data);
      this.EMIT_REQUEST("change:checkedList");
    },
    checkValue(data) {
      this.checkedList = data;
    },
    searchValue(data) {
      this.search = data;
    },
  },
  computed: {
    allChecked() {
      return this.checkedList.length === this.items.length;
    },
  },
  methods: {
    EMIT_REQUEST(action, headers = this.headersData) {
      if (!this.init) return;

      setTimeout(() => {
        const sort = headers.find((h) => h.sort);
        this.$emit(
          "request",
          {
            action,
            sort,
            headers,
            selectedAll: this.selectedAll,
            checkedList: this.checkedList,
            search: this.search,
            count: this.countItem,
            page: this.page,
          },
          action
        );
      }, 100);
    },
    SET_PAGINATION(page) {
      this.$emit("change:page", page);
      this.EMIT_REQUEST("change:page");
    },
    SET_CHANGE_SORT(header, headers) {
      this.headersData = headers;
      this.$emit("change:sort", headers);
      this.EMIT_REQUEST("change:sort", headers);
    },
    SET_CHECKED_ALL(data, getAllItem = false) {
      this.selectedAll = getAllItem;
      if (getAllItem) this.$emit("change:selectAllWithoutPage", true);
      if (data) {
        this.checkedList = [...this.items];
      } else {
        this.$emit("change:selectAllWithoutPage", false);
        this.checkedList = [];
      }
      this.EMIT_REQUEST("change:selectAllWithoutPage");
    },
    SET_CHECK_LIST(data) {
      this.selectedAll = false;
      this.$emit("change:selectAllWithoutPage", false);
      const index = this.checkedList.findIndex(
        // (item) => item[this.checkedKey] === data[this.checkedKey]
        (item) => this.checkedKey.every((ck) => item[ck] === data[ck])
      );
      if (index === -1 && this.singleCheckable) {
        this.checkedList = [];
        this.checkedList.push(data);
      } else if (index === -1) this.checkedList.push(data);
      else this.checkedList.splice(index, 1);
    },
    SET_SUBMIT(e) {
      e.preventDefault();
      if (this.searchTimeout !== -1) clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.$emit("change:search", this.search);
        this.EMIT_REQUEST("change:search");
      }, 1000);
    },
  },
  components: { Header, Body },
  mounted() {
    this.scopedSlots = Object.keys(this.$scopedSlots);
    if (this.count === 0) this.countItem = this.items.length;
    else this.countItem = this.count;
    this.search = this.searchValue;
    this.checkedList = this.checkValue;
    this.headersData = this.headers;
    if (this.scopedSlots.includes("filter")) this.filterSlot = true;
    if (this.scopedSlots.includes("new-button")) this.newButtonSlot = true;
    setTimeout(() => {
      this.init = true;
    }, 250);
  },
};
</script>
