<template>
  <div class="c-checkbox">
    <div class="c-checkbox__form-group">
      <input
        :checked="value"
        @change="(e) => $emit('change', e)"
        class="c-checkbox__form-group__input"
        :class="{ 'c-checkbox__form-group__input--opposite': opposite }"
        type="checkbox"
        :id="rndId"
      />
      <label
        class="c-checkbox__form-group__label"
        :class="{
          'c-checkbox__form-group__label--opposite': opposite,
          'c-checkbox__form-group__label--not-all': notAll,
        }"
        :for="rndId"
        >{{ label }}</label
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: false,
      type: Boolean,
    },
    notAll: {
      default: false,
      type: Boolean,
    },
    opposite: {
      default: false,
      type: Boolean,
    },
    label: {
      default: "",
    },
  },
  data() {
    return {
      rndId: "",
    };
  },
  methods: {
    makeid(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    },
  },
  mounted() {
    this.rndId = this.makeid(5);
  },
};
</script>

<style></style>
