import router from '../../router';
import { baseUrl } from '../Api';
import store from '@/store'
/** Get without token */
export const getNoToken = (url) => {
    const axios = require('axios');

    let config = {
        method: 'get',
        url: `${baseUrl}${url}`,
        headers: {
            "Content-type": "application/json",
        },
    }
    return axios(config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            const err = error.response
            if (err.status === 401 || err.status === 403) {
                store.dispatch('SET_LOGOUT_ACTION');
                router.push('/')
            }
            // localStorageManager.userAuth.removeActiveUser();
            // window.location.href = '/user/giris-yap'
            return error;
        });
}