var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.headers.length !== 0)?_c('div',{staticClass:"o-table__header"},[(_vm.checkable || _vm.singleCheckable)?_c('div',{staticClass:"col-xs-1"},[(_vm.checkable)?_c('Checkbox',{key:_vm.checkboxKey,attrs:{"not-all":_vm.checkedList.length !== _vm.items.length,"opposite":"","value":_vm.checkedList.length !== 0},on:{"change":function($event){return _vm.$emit(
          'change:checkedAll',
          _vm.checkedList.length !== _vm.items.length
            ? true
            : _vm.checkedList.length === 0
            ? true
            : false
        )}}}):_vm._e()],1):_vm._e(),_vm._l((_vm.headersData),function(header,i){return _c('div',{key:'c' + i,staticClass:"o-table__header__item",class:`
    ${header.col ? `col-xs-${header.col}` : ''}
    ${header.sm ? `col-sm-${header.sm}` : ''}
    ${header.md ? `col-md-${header.md}` : ''}
    ${header.lg ? `col-lg-${header.lg}` : ''}
    ${header.xl ? `col-xl-${header.xl}` : ''}
    ${header.xxl ? `col-xxl-${header.xxl}` : ''}
    ${header.sortable ? 'o-table__header__item--sortable' : ''}
    `,on:{"click":function($event){return _vm.SET_SORT_OF_HEADER(header, i)}}},[_vm._v(" "+_vm._s(_vm.i18n ? _vm.$t(header.title) : header.title)+" "),(header.sortable && header.sort !== '')?_c('i',{staticClass:"o-table__header__item__sort-icon",class:`icofont-arrow-${
        header.sort?.includes('desc')
          ? 'up'
          : 'up o-table__header__item__sort-icon--reverse'
      }`}):_vm._e()])})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }