import { baseUrl } from '../Api';
import localStorageManager from '../localStorageManager';
import store from '@/store'
/** delete token */
export const deleteToken = (url, data) => {
    const token = localStorageManager.userAuth.getUserToken()

    const axios = require('axios');

    let config = {
        method: 'delete',
        url: `${baseUrl}${url}`,
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
    }
    return axios(config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            const err = error.response
            if (err.status === 401 || err.status === 403) {
                store.dispatch('SET_LOGOUT_ACTION');
            }
            // localStorageManager.userAuth.removeActiveUser();
            // window.location.href = '/user/giris-yap'
            return error;
        });
}