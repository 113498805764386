<template>
  <div class="o-table__body">
    <div
      class="o-table__body__item"
      :class="{
        'o-table__body__item--active':
          checkedList.find((data) =>
            checkedKey.every((ck) => item[ck] === data[ck])
          ) !== undefined,
        'u-flex-wrap-wrap o-table__body__item--small-table': smallTable,
      }"
      v-for="(item, i) in items"
      :key="'b' + i"
    >
  
      <div v-if="checkable || singleCheckable" class="col-xs-1">
        <Checkbox
          @change="$emit('change:checked', item)"
          :value="
            checkedList.find((data) =>
              checkedKey.every((ck) => item[ck] === data[ck])
            ) !== undefined
          "
        ></Checkbox>
      </div>
      <div
        v-for="(header, j) in headers"
        :key="i + '-' + j"
        :class="`
              ${header.col ? `col-xs-${header.col}` : ''}
              ${header.sm ? `col-sm-${header.sm}` : ''}
              ${header.md ? `col-md-${header.md}` : ''}
              ${header.lg ? `col-lg-${header.lg}` : ''}
              ${header.xl ? `col-xl-${header.xl}` : ''}
              ${header.xxl ? `col-xxl-${header.xxl}` : ''}`"
      >
        <slot
          :item="item"
          :index="i"
          :name="header.value"
          v-if="$scopedSlots[header.value]"
        ></slot>
        <div v-else>
          {{ i18n ? $t(item[header.value]) : item[header.value] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from "../Checkbox.vue";
export default {
  components: {
    Checkbox,
  },
  props: {
    items: {
      default: () => [],
    },
    headers: {
      default: () => [],
    },
    checkedList: {
      default: () => [],
    },
    allChecked: {
      default: false,
    },
    checkable: {
      default: false,
    },
    singleCheckable: {
      default: false,
    },
    smallTable: {
      default: false,
    },
    scrollLeft: {
      default: 0,
    },
    checkedKey: {
      default: () => ["id"],
    },
    i18n: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    SET_SCROLL_EVENT(e) {
      this.$emit("change:scroll", e.target.scrollLeft);
      document.getElementById("table-header").scrollLeft = e.target.scrollLeft;
      const bodies = document.querySelectorAll(".overflow-x-scroll");
      for (let i = 0; i < bodies.length; i++) {
        const el = bodies[i];
        el.scrollLeft = e.target.scrollLeft;
      }
    },
  },
};
</script>

<style></style>
