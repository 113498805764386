<template>
  <div class="o-table__header" v-if="headers.length !== 0">
    <div v-if="checkable || singleCheckable" class="col-xs-1">
      <Checkbox
        :key="checkboxKey"
        @change="
          $emit(
            'change:checkedAll',
            checkedList.length !== items.length
              ? true
              : checkedList.length === 0
              ? true
              : false
          )
        "
        :not-all="checkedList.length !== items.length"
        opposite
        :value="checkedList.length !== 0"
        v-if="checkable"
      ></Checkbox>
    </div>
    <div
      v-for="(header, i) in headersData"
      :key="'c' + i"
      @click="SET_SORT_OF_HEADER(header, i)"
      class="o-table__header__item"
      :class="`
      ${header.col ? `col-xs-${header.col}` : ''}
      ${header.sm ? `col-sm-${header.sm}` : ''}
      ${header.md ? `col-md-${header.md}` : ''}
      ${header.lg ? `col-lg-${header.lg}` : ''}
      ${header.xl ? `col-xl-${header.xl}` : ''}
      ${header.xxl ? `col-xxl-${header.xxl}` : ''}
      ${header.sortable ? 'o-table__header__item--sortable' : ''}
      `"
    >
      {{ i18n ? $t(header.title) : header.title }}

      <i
        v-if="header.sortable && header.sort !== ''"
        class="o-table__header__item__sort-icon"
        :class="`icofont-arrow-${
          header.sort?.includes('desc')
            ? 'up'
            : 'up o-table__header__item__sort-icon--reverse'
        }`"
      ></i>
    </div>
  </div>
</template>

<script>
import Checkbox from "../Checkbox.vue";
export default {
  components: {
    Checkbox,
  },
  props: {
    headers: {
      default: () => [],
    },
    items: {
      default: () => [],
    },
    checkedList: {
      default: () => [],
    },
    checkable: {
      default: false,
      type: Boolean,
    },
    singleCheckable: {
      default: false,
      type: Boolean,
    },
    allChecked: {
      default: false,
      type: Boolean,
    },
    i18n: {
      default: false,
      type: Boolean,
    },
    scrollLeft: {
      default: 0,
    },
  },
  data() {
    return {
      checkboxKey: 1,
      headersData: [],
      resetHeadersData: this.headers,
    };
  },
  watch: {
    "checkedList.length"() {
      this.checkboxKey += 1;
    },
  },
  methods: {
    CLEAR_SORT() {
      this.headersData = this.headersData.map((header) => ({
        ...header,
        sort: "",
      }));
    },
    SET_SORT_OF_HEADER(header, i) {
      if (!header.sortable) return;
      const sort = header.sort;
      if (sort?.includes("asc"))
        this.headersData[i].sort = `${header.value}-desc`;
      else if (sort?.includes("desc"))
        this.headersData[i].sort = `${header.value}-asc`;
      else {
        this.CLEAR_SORT();
        this.headersData[i].sort = `${header.value}-desc`;
      }

      this.$emit("change:sort", this.headersData[i], this.headersData);
    },
  },
  mounted() {
    this.headersData = this.headers;
  },
};
</script>

<style></style>
