export default (object) => {
    let data = new FormData();
    for (const key in object) {
        if (Object.hasOwnProperty.call(object, key)) {
            const element = object[key];
            data.append(key, element)

        }
    }
    return data;
}